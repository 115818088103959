@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* /* @import url("https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@400;600&family=Nunito+Sans:wght@300;400&family=Varela+Round&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Nunito+Sans:wght@200;300;400&display=swap");
/* 
    font-family: 'Inknut Antiqua', serif;

    font-family: 'Nunito Sans', sans-serif;

    font-family: 'Varela Round', sans-serif;
*/

/* Main overarching styles */

:root {
  --body: "Nunito Sans", sans-serif;
  --title: "Inknut Antiqua", serif;
  --subTitle: "Varela Round", sans-serif;
}
a {
  color: black;
  text-decoration: none;
}
body {
  margin: 0;
  font-family: var(--body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
}
main {
  margin: 0;
  font-size: 18px;
}
h1 {
  font-family: var(--title);
}
.title {
  font-family: var(--title);
}
.subTitle {
  font-family: var(--subTitle);
}
